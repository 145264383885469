<template>
    <div class="user-content">
        <el-button class="ok-button checkbox-button" size="small">Users</el-button>
        <el-button class="cancel-button checkbox-button" size="small">Roles</el-button>
        <v-table ref="userTable"
                 :table-head="tableHead"
                 :table-data="userData"
                 :is-show-page="false"
                 @deleteRow="deleteUser"
                 @editRow="editUser"
                 @rowClick="handleRowClick"
                 class="user-table"
        >
            <template slot="Name" slot-scope="scope">
                <div class="avatar-area">
                    <div class="text-overflow-ellipsis"
                         :title="scope.row.Name">{{scope.row.Name}}
                    </div>
                    <!--</el-link>-->
                </div>

            </template>
            <template slot="FaceID" slot-scope="scope">
                <span v-if="scope.row.FRPictures"> <i class="el-icon-check state-check"></i></span>
                <span v-else> <i class="el-icon-close state-no-check"></i></span>
            </template>

        </v-table>
    </div>
</template>

<script>
    import vTable from '../../../components/common/Table';

    export default {
        name: "UserList",
        components: {
            vTable
        },
        data() {
            return {
                tableHead: [
                    {
                        name: "Name",
                        prop: "name",
                        minWidth: "150"
                    },
                    {
                        name: "Email Address",
                        prop: "email",
                        minWidth: "160"
                    },
                    {
                        name: "Contact Number",
                        prop: "contact",
                        minWidth: "150"

                    },
                    {
                        name: "User ID",
                        prop: "userId",
                        minWidth: "150",

                    },
                    {
                        name: "Job Title",
                        prop: "jobTitle",
                        minWidth: "150",

                    },
                    {
                        name: "User Role",
                        prop: "role",
                        minWidth: "150",

                    },
                    {
                        name: "Fleet",
                        prop: "fleet",
                        align: 'center'

                    }
                ],
                userData: [
                    {
                        name: "Stanley Sun",
                        email: "stanleysun@gmail.com",
                        contact: '12565555999',
                        userId: 'LTI-Software001',
                        jobTitle: 'Taipei Administrator',
                        role: 'Administrator',
                        fleet: 1
                    },
                    {
                        name: "Amanda Zhu",
                        email: "amandazhu@gmail.com",
                        contact: '12565555999',
                        userId: 'LTI-Software002',
                        jobTitle: 'Taipei Administrator',
                        role: 'Administrator',
                        fleet: 0
                    },
                    {
                        name: "JoeCC",
                        email: "stanleysun@gmail.com",
                        contact: '12565555999',
                        userId: 'LTI-Software003',
                        jobTitle: 'Taipei Manager',
                        role: 'Administrator',
                        fleet: 3
                    }
                ]
            }
        },
        methods: {
            deleteUser() {

            },
            editUser() {

            },
            handleRowClick() {

            }
        }
    }
</script>

<style scoped lang="stylus">
    @import "~@/assets/stylus/color.styl"
    .user-content {
        padding: 20px 20px 20px 30px;
    }

    .user-table {
        margin-top 30px
    }

    .checkbox-button{
        border-radius 0;
        width 180px
        margin-right 3px
        margin-left 0
    }

    >>> .el-table td, >>> .el-table th {
        padding: 6px 0 !important;
    }


</style>
