<template>
    <div>
        <div v-if="isShowNoSearchData" style="text-align: center;line-height: 80px">{{$t('globalText.noData')}}</div>
        <el-table
                v-if="!isShowNoSearchData"
                :data.sync="currentPageData"
                stripe
                tooltip-effect="light"
                highlight-current-row
                :empty-text="emptyText"
                :expand-row-keys="expandRowKeys"
                :row-key="rowKey"
                class="table-content"
                ref="tablecontent"
                @selection-change="handleSelectionChange"
                @row-click="handleRowClick"
                @sort-change="handleSortChange"
                :default-sort="currentSort">
            <el-table-column v-if="isShowCheckBox"
                             type="selection"
                             width="50"
                             :selectable="selectable">
            </el-table-column>
            <template v-for="(item,index) in THead">
                <el-table-column
                        :label="item.name"
                        :prop="item.prop"
                        :width="item.width"
                        :min-width="item.minWidth"
                        :sortable="item.sortable"
                        :align="item.align"
                        :type="item.type"
                        v-bind:key="index">
                    <template slot-scope="scope">
                        <slot :row="scope.row" :name="item.prop">
                            <div class="text-overflow-ellipsis" :title="scope.row[item.prop]">
                                {{scope.row[item.prop]}}
                            </div>
                        </slot>
                    </template>
                </el-table-column>
            </template>
            <el-table-column align="right" v-if="isHasEditColumn">
                <template slot-scope="scope">
                    <span class="via-edit edit-button" v-show="editable && editable(scope.row)" @click.stop="editRow(scope.row)"
                          style="padding-right: 30px"></span>
                    <slot name="operator" :row="scope.row">
                    </slot>
                </template>
            </el-table-column>

        </el-table>
        <div class="bottom-area" v-if="isShowBottomArea && !isShowNoSearchData">
            <div style="float: left">
                <el-button v-if="multipleSelection.length>0" class="delete-button" size="small"
                           @click="deleteRow(multipleSelection)" :loading="isDeleting">
                    <span
                            class="via-delete"></span>&nbsp;&nbsp;{{$t('globalText.delete')}}
                </el-button>
                <el-button v-else class="disable-delete-button" size="small" disabled><span
                        class="via-delete"></span>&nbsp;&nbsp;{{$t('globalText.delete')}}
                </el-button>
                <slot name="button-area"></slot>
            </div>
            <div class="table-page" v-if="isShowPage">
                <el-pagination
                        background
                        :pager-count.sync="pageCount"
                        layout="prev, pager, next"
                        :current-page.sync="pageInfo.currentPage"
                        :total.sync="TData.length"
                        :page-size.sync="pageInfo.pageSize"
                        @current-change="pageChange">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Table",
        props: {
            tableHead: {type: Array},
            tableData: {type: Array},
            defaultPage: {type: Number},
            defaultSort: {type: Object},
            isShowCheckBox: {type: Boolean, default: true},
            isShowBottomArea: {type: Boolean, default: true},
            isHasEditColumn: {type: Boolean, default: true},
            isShowPage: {type: Boolean, default: false},
            expandRowKeys: {type: Array},
            rowKey: {type: [String, Number]},
            sortData: {type: Array},
            selectable: {type: Function,default:()=>{return true;}},
            editable: {type: Function,default:()=>{return true;}},
        },
        data() {
            return {
                THead: this.tableHead,
                TData: [],
                multipleSelection: [],
                pageInfo: {
                    currentPage: 1,
                    pageSize: this.setPageSize()
                },
                isDeleting: false,
                emptyText: this.$t('globalText.loading'),
                currentSort: {},
                defaultPageSize: 9,
                lastSortOrder: null,
                lastSortObj: null,
            }
        },
        computed: {
            isShowNoSearchData:function(){
                return this.expandRowKeys && this.expandRowKeys.length === 0 && this.$store.getters.getVehicleSearchKey !== '';
            },
            currentPageData: function () {
                if (!this.isShowPage)
                    return this.TData;
                if (this.TData)
                    return this.TData.slice((this.pageInfo.currentPage - 1) * this.pageInfo.pageSize, this.pageInfo.currentPage * this.pageInfo.pageSize)
            },
            pageCount: function () {
                return this.setPageCount();
            }
        },
        watch: {
            '$store.getters.screenHeight'() {
                if (this.isShowPage) {
                    var height = document.body.clientHeight;
                    this.pageInfo.pageSize = this.setPageSize(height);
                }
            },
            tableHead: function (val) {
                this.THead = val;
            },
            tableData: {
                immediate: true,
                handler: function (val) {
                    if (val) {
                        this.TData = [...val];
                        if (val.length === 0) {
                            this.emptyText = this.$t('globalText.noData');
                        }
                    }else {
                        this.emptyText = this.$t('globalText.loading');
                    }

                }
            },
            defaultPage: {
                immediate: true,
                handler: function (val) {
                    this.pageInfo.currentPage = val;
                }
            },
            // defaultSort: {
            //     immediate: true,
            //     handler: function (val) {
            //         this.currentSort = val;
            //         this.handleSortChange(val);
            //     }
            // },
        },
        methods: {
            setPageSize(height) {
                if (!height) height = document.body.clientHeight;
                return parseInt((height - 275) / 56);
            },
            setPageCount() {
                if (this.TData.length / this.pageInfo.pageSize > this.defaultPageSize) {
                    return this.defaultPageSize - 2;
                } else {
                    return this.defaultPageSize;
                }

            },
            getSelectedIds() {
                var Ids = this.multipleSelection.map((item) => {
                    return item.Id
                });
                return Ids;
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
                this.$emit("selectChange",val);
            },
            handleRowClick(row) {
                this.$emit("rowClick", row);
            },
            handleSortChange(data) {
                if (this.lastSortObj && this.lastSortObj.prop !== data.prop) {
                    this.lastSortObj.column.order = null;
                    this.lastSortObj.order = null;
                }
                const ASCE = 'ascending', DESC = 'descending';
                if (data.order === null) {
                    if (this.lastSortOrder === null)
                        this.lastSortOrder = ASCE;
                    else
                        this.lastSortOrder = this.lastSortOrder === 'ascending' ? DESC : ASCE;
                    data.column.order = this.lastSortOrder;
                } else {
                    this.lastSortOrder = data.column.order;
                }

                if (data.order == null) {
                    this.currentSort.order === 'descending' ? data.order = 'ascending' : data.order = 'descending'
                    data.column.order = data.order
                }
                this.lastSortObj = data;

                this.currentSort = {
                    order:data.order,
                    prop:data.prop
                };
                this.$emit("sortChange", this.currentSort);
            },
            pageChange(current) {
                this.pageInfo.currentPage = current;
                this.$emit("pageChange", current);
                this.$refs.tablecontent.doLayout();
            },
            editRow(row) {
                this.$emit("editRow", row);
            },
            deleteRow(data) {
                var comfirmText;
                var isMultiDelete = false;
                var delIds = [];
                comfirmText = this.$t('globalText.confirmDeletion');
                if (data instanceof Array) {
                    isMultiDelete = true;
                    delIds = data.map((item) => {
                        return item.Id
                    })
                } else {
                    delIds[0] = data.Id;
                }
                this.$customConfirm(comfirmText, {
                    type: 'warning'
                }).then(() => {
                    this.isDeleting = true
                    let callback = () => {
                        this.isDeleting = false
                    }
                    this.$emit("deleteRow", delIds, callback);
                }).catch(() => {
                });


            },
            getCurrentSort() {
                return this.currentSort;
            },
            toggleRowExpansion(row, expand) {
                this.$refs.tablecontent.toggleRowExpansion(row, expand);
            }

        },
        created() {
            this.currentSort = this.defaultSort;
        }
    }

</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .el-row {
        padding: 0 0 20px 0;
    }

    .table-content {
        /*height: calc(100% - 110px);*/
        overflow-y: auto;
    }

    .table-page {
        padding-right: 100px;
        float: right;
    }

    .table-content::before {
        height: 0;
    }

    .bottom-area {
        height: 40px;
        margin-top: 20px;
        width: calc(100% - 50px);
    }

    >>> .el-table .caret-wrapper {
        width 16px
    }

    >>> .el-table .ascending .sort-caret.ascending,
    >>> .el-table .descending .sort-caret.descending
        border-top-color rgba(0, 0, 0, 0)
        border-bottom-color rgba(0, 0, 0, 0)
        font-family: 'via' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

    >>> .el-table .descending .sort-caret.descending::before
        content "\e940"
        color #ffffff
        position relative
        top -14px
        left -5px
        font-size 12px

    >>> .el-table .ascending .sort-caret.ascending::before
        content "\e93f"
        color #ffffff
        position relative
        top -2px
        left -5px
        font-size 12px

    >>> .el-table .ascending .sort-caret.ascending, >>> .el-table .ascending .sort-caret.descending,
    >>> .el-table .descending .sort-caret.ascending, >>> .el-table .descending .sort-caret.descending
        border-bottom-color rgba(0, 0, 0, 0)
        border-top-color rgba(0, 0, 0, 0)

    >>> .el-table .sort-caret.ascending
        border-bottom-color $color_white
        top: 0
        left: 4px

    >>> .el-table .sort-caret.descending
        border-top-color $color_white
        bottom: 0
        left: 4px

    >>> .el-tooltip__popper {
        display none !important
    }

    >>> .el-table .cell {
        word-break: break-word !important
    }

</style>
<style lang="stylus">
    @import "~@/assets/stylus/color.styl"
    .table-content .el-checkbox__inner {
        border-color: $color_black_third;
    }
    .table-content .el-checkbox.is-disabled{
        display none;
    }

        .table-content .el-table__body {
        width 100% !important;
    }

    .table-content .el-table__body-wrapper:not(.el-table__row) {
        cursor pointer
    }

    .table-content .caret-wrapper {
        height 23px;
    }

    .table-content td, .table-content th.is-leaf {
        border-bottom: 1px solid $color_gray_third;
    }

    .table-page .el-pager li, .table-page .el-pagination button {
        height: 38px;
        line-height: 38px;
        min-width: 40px !important;
    }

    .table-content td:first-child {
        border-left: 1px solid $color_gray_third;
    }

    .table-content td:last-child {
        border-right: 1px solid $color_gray_third;
    }

    .table-content th {
        color: white;
        background-color: $color_blue_second;
    }

    .table-content th.gutter {
        display inline-block !important;
    }

    .table-content .el-table__body tr.el-table__row--striped td {
        background: $color_gray_first;
    }

    .table-content tr:hover > td {
        background-color: $color_blue_hover !important;

    }

    .table-content .el-table__empty-block {
        border: 1px solid $color_gray_third;
        border-top: none;
        box-sizing border-box !important;
    }


</style>
